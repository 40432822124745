import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/views/Home.vue'
import Method from '@/views/Method.vue'
import Schedule from '@/views/Schedule.vue'
import HighSchool from '@/views/HighSchool.vue'
import Announcements from '@/views/Announcements.vue'
import ELearning from '@/views/ELearning.vue'
import Contact from '@/views/Contact.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/methodos',
    name: 'Method',
    component: Method
  },
  {
    path: '/programmata',
    name: 'Schedule',
    component: Schedule
  },
  {
    path: '/neolykeio',
    name: 'HighSchool',
    component: HighSchool
  },
  {
    path: '/anakoinoseis',
    name: 'Announcements',
    component: Announcements
  },
  {
    path: '/elearn',
    name: 'E-Learning',
    component: ELearning
  },
  {
    path: '/epikoinonia',
    name: 'Contact',
    component: Contact
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router