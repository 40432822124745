<template>
    <div>
        <TitleBar />

        <p class="page-title big-text">Επικοινωνήστε μαζί μας</p>

        <div class="text-area readable-text">
            <ul>
                <li>
                    Μέσω τηλεφώνου στο <a href="tel:+302105129202">2105129202</a>
                </li>
                <li>
                    Μέσω e-mail στη διεύθυνση <a href="mailto:hiqstudies@yahoo.com">hiqstudies@yahoo.com</a>
                </li>
                <li>
                    Ή από κοντά στη διεύθυνση του φροντιστηρίου μας Λένορμαν 266. (βλ. χάρτη)
                </li>
            </ul>
        </div>

        <Map class="map" :lat=38.000891 :lon=23.706388 :zoom=19 />
    </div>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import Map from '@/components/Map.vue'

export default {
    name: 'Contact',
    components: {
        TitleBar,
        Map
    }
}
</script>

<style scoped>
li {
    margin-left: 1.3rem;
}

.map {
    width: 60em;
    max-width: 90%;
    height: 30em;
    margin-top: 5rem;
    margin-bottom: 3rem;
    margin-left: auto;
    margin-right: auto;
}
</style>