<template>
    <div id="map-holder"></div>
</template>

<script>
import Map from 'ol/Map'
import TileLayer from 'ol/layer/Tile'
import OSM from 'ol/source/OSM'
import View from 'ol/View'
import {fromLonLat} from 'ol/proj'

export default {
    name: 'Map',
    props: {
        lat: Number,
        lon: Number,
        zoom: Number
    },
    mounted() {
        new Map({
            target: 'map-holder',
            layers: [
                new TileLayer({
                    source: new OSM()
                })
            ],
            view: new View({
                center: fromLonLat([this.lon, this.lat]),
                zoom: this.zoom
            })
        });
    }
}
</script>

<style scoped>
@import "~ol/ol.css"
</style>