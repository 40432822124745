<template>
    <div>
        <TitleBar />

        <p class="page-title big-text">Ανακοινώσεις</p>

        <div class="content">
            <Announcement date="27/08/2020" title="ΕΓΓΡΑΦΕΣ 2020">
                Οι εγγραφές αρχίζουν την 1η Σεπτεμβρίου.
                <br /><br />
                Ώρες Γραμματείας: 10:30 π.μ - 12:00 μ.μ και 15:00 μ.μ - 20:00 μ.μ.
            </Announcement>
        </div>
    </div>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import Announcement from '@/components/Announcement.vue'

export default {
    name: 'Announcements',
    components: {
        TitleBar,
        Announcement
    }
}
</script>

<style scoped>
.content {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}
</style>