<template>
    <router-link :to="name" class="icon-button">
        <img class="no-drag no-select" :src="icon" :alt="name" />
        <p class="no-select" v-html="description"></p>
    </router-link>
</template>

<script>
export default {
    name: 'IconButton',
    props: ['icon', 'name', 'description']
}
</script>

<style scoped>
.icon-button {
    display: inline-block;
    padding: 0.8em 1.3em;
    border: 0.1em solid transparent;
    margin-bottom: 2.5em;
    text-align: center;
    text-decoration: none;
}

.icon-button:hover {
    background-color: rgba(173, 216, 230, 0.8);
    border-color: lightblue;
}

img {
    width: 4.1em;
    height: 4.1em;
}

p {
    color: white;
    margin-top: 0.3em;
    text-shadow: 0.1em 0.1em 0.2em black;
}
</style>