<template>
    <div>
        <TitleBar />
        
        <p class="page-title big-text">Η μέθοδός μας</p>

        <div class="text-area readable-text">
            Οι μαθητές του Δημοτικού (Γ' - Στ') και του Γυμνασίου προετοιμάζονται <u>καθημερινά σε όλα τα μαθήματα</u>
            της επόμενης μέρας. Με τη βοήθεια εξειδικευμένων καθηγητών, θέτουμε τις σωστές βάσεις στη μελέτη
            <u>από τα πρώτα χρόνια της σχολικής ζωής</u>.
            <br /><br />
            Οι μαθητές μας εμπεδώνουν καθημερινά τη σχολική ύλη και ταυτόχρονα μαθαίνουν να μελετούν σωστά.
            Μέσω της συχνής και συστηματικής επανάληψης είναι καλύτερα προετοιμασμένοι για τα διαγωνίσματα
            και τις εξετάσεις.
            <br /><br />
            Η μέθοδός μας στηρίζεται:
            <ul>
                <li>στα ολιγομελή τμήματα.</li>
                <li>στη συνεχή παρακολούθηση της προόδου του κάθε μαθητή.</li>
                <li>στην άριστη ομάδα έμπειρων καθηγητών.</li>
                <li>
                    στη διδασκαλία σε ενιαίο τραπέζι που στοχεύει στην ανάπτυξη της άριστης συνεργασίας
                    μεταξύ των μαθητών.
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'

export default {
    name: 'Method',
    components: {
        TitleBar
    }
}
</script>

<style scoped>
li {
    margin-left: 1.3rem;
}
</style>