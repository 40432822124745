<template>
  <div class="home">
    <TitleBar />
    <BgImage class="background-img" />

    <div class="left-icons">
      <IconButton :icon="icons.method" name="methodos" description="Η μέθοδός μας" />
      <IconButton :icon="icons.education" name="programmata" description="Προγράμματα<br/>Σπουδών" />
      <IconButton :icon="icons.high" name="neolykeio" description="Σχετικά με<br>το Λύκειο<br/>2020-2021" />
    </div>

    <div class="right-icons">
      <IconButton :icon="icons.announcements" name="anakoinoseis" description="Ανακοινώσεις" />
      <IconButton :icon="icons.elearn" name="elearn" description="Σχετικά με το<br>E-Learning" />
      <IconButton :icon="icons.mail" name="epikoinonia" description="Επικοινωνία" />
    </div>

    <h2 class="left-subject no-select">
      Κέντρο Μελέτης <br /> για Δημοτικό <br /> και Γυμνάσιο
    </h2>

    <h2 class="right-subject no-select">
      Φροντιστήριο <br /> για το Λύκειο
    </h2>
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import BgImage from '@/components/BgImage.vue'
import IconButton from '@/components/IconButton.vue'

export default {
  name: 'Home',
  data() {
    return {
      icons: {
        announcements: require('@/assets/icons/announcements.webp'),
        elearn: require('@/assets/icons/e-learn.webp'),
        education: require('@/assets/icons/education.webp'),
        high: require('@/assets/icons/high.webp'),
        mail: require('@/assets/icons/mail.webp'),
        method: require('@/assets/icons/method.webp')
      }
    }
  },
  components: {
    TitleBar,
    BgImage,
    IconButton
  }
}
</script>

<style scoped>
.background-img {
  position: fixed;
  top: 3em;
  left: 0;
  width: 100vw;
  height: calc(100vh - 3em);
}

.left-icons {
  display: inline-flex;
  flex-flow: column nowrap;
  position: fixed;
}

.right-icons {
  display: inline-flex;
  flex-flow: column nowrap;
  position: fixed;
  right: 0;
}

.left-subject, .right-subject {
  position: fixed;
  bottom: 0;
  padding: 0.5em;
  text-align: center;
  font-size: 1rem;
  color: rgb(240, 240, 240);
  background-color: rgba(30, 30, 30, 0.6);
}

.left-subject {
  left: 0;
  border-radius: 0 1em 0 0;
}

.right-subject {
  right: 0;
  border-radius: 1em 0 0 0;
}
</style>
