<template>
    <div>
        <div class="ann-head">
            <p class="ann-date readable-text">{{ date }}</p>
            <p class="ann-title readable-text">{{ title }}</p>
        </div>
        <div class="ann-content text-area readable-text">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Announcement',
    props: {
        date: String,
        title: String
    }
}
</script>

<style scoped>
.ann-head {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    max-width: 50vw;
    margin-bottom: 0.5em;
}

.ann-date {
    padding: 0.18em;
    border: 0.085em solid rgb(240, 240, 240);
    margin-bottom: 0.6em;
}

.ann-title {
    margin-left: 1em;
    font-weight: bold;
    text-decoration: underline;
}

.ann-content {
    max-width: 50vw;
}
</style>