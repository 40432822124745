<template>
    <div>
        <TitleBar />

        <p class="page-title big-text">Προγράμματα Σπουδών</p>

        <div class="content">
            <ScheduleButton name="Γ' - Στ' Δημοτικού" color="rgb(0, 200, 60)">
                Καθημερινή προετοιμασία σε όλα τα μαθήματα της επόμενης μέρας. (14:00 - 16:00)
            </ScheduleButton>

            <ScheduleButton name="Γυμνάσιο" color="rgb(0, 125, 255)">
                Καθημερινή <u>τρίωρη</u> προετοιμασία σε όλα τα μαθήματα της επόμενης μέρας. (15:00 - 18:00)
            </ScheduleButton>

            <ScheduleButton name="Α' Λυκείου" color="rgb(240, 240, 0)">
                <table>
                    <tr>
                        <th>Μάθημα</th>
                        <th>Ώρες</th>
                    </tr>
                    <tr>
                        <td>Άλγεβρα</td>
                        <td>3</td>
                    </tr>
                    <tr>
                        <td>Γεωμετρία</td>
                        <td>2</td>
                    </tr>
                    <tr>
                        <td>Φυσική</td>
                        <td>3</td>
                    </tr>
                    <tr>
                        <td>Χημεία</td>
                        <td>2</td>
                    </tr>
                    <tr>
                        <td>Έκθεση - Λογοτεχνία</td>
                        <td>2</td>
                    </tr>
                    <tr>
                        <td>Αρχαία Ελληνικά</td>
                        <td>2</td>
                    </tr>
                    <tr>
                        <td>Βιολογία</td>
                        <td>1</td>
                    </tr>
                    <tr>
                        <th>Σύνολο</th>
                        <td>15</td>
                    </tr>
                </table>
                <br />
                Προσφέρεται και βοήθεια σε δευτερεύοντα μαθήματα όπως Ιστορία κ.λ.π.
            </ScheduleButton>

            <ScheduleButton name="Β' Λυκείου" color="rgb(255, 150, 0)">
                <table>
                    <tr>
                        <th>Μαθήματα</th>
                        <th>2ο πεδίο</th>
                        <th>3ο πεδίο</th>
                        <th>4ο πεδίο</th>
                    </tr>
                    <tr>
                        <th></th>
                        <th></th>
                        <th>Ώρες</th>
                    </tr>
                    <tr>
                        <td>Έκθεση</td>
                        <td>2.5</td>
                        <td>2.5</td>
                        <td>2.5</td>
                    </tr>
                    <tr>
                        <td>Άλγεβρα</td>
                        <td>2</td>
                        <td>2</td>
                        <td>2</td>
                    </tr>
                    <tr>
                        <td>Φυσική</td>
                        <td>2.5</td>
                        <td>2.5</td>
                        <td>2</td>
                    </tr>
                    <tr>
                        <td>Χημεία</td>
                        <td>2</td>
                        <td>2</td>
                        <td>1</td>
                    </tr>
                    <tr>
                        <td>Μαθηματικά<br />(επιπλέον)</td>
                        <td>2</td>
                        <td>-</td>
                        <td>2</td>
                    </tr>
                    <tr>
                        <td>Βιολογία</td>
                        <td>1</td>
                        <td>2</td>
                        <td>1</td>
                    </tr>
                    <tr>
                        <td>Πληροφορική</td>
                        <td>-</td>
                        <td>-</td>
                        <td>1</td>
                    </tr>
                    <tr>
                        <td>Αρχαία Ελληνικά</td>
                        <td>2</td>
                        <td>2</td>
                        <td>2</td>
                    </tr>
                    <tr>
                        <th>Σύνολο</th>
                        <td>14</td>
                        <td>13</td>
                        <td>13.5</td>
                    </tr>
                </table>
            </ScheduleButton>

            <ScheduleButton name="Γ' Λυκείου" color="rgb(230, 0, 0)">
                <table>
                    <tr>
                        <th>Μαθήματα</th>
                        <th>2ο πεδίο</th>
                        <th>3ο πεδίο</th>
                        <th>4ο πεδίο</th>
                    </tr>
                    <tr>
                        <th></th>
                        <th></th>
                        <th>Ώρες</th>
                    </tr>
                    <tr>
                        <td>Έκθεση</td>
                        <td>3</td>
                        <td>3</td>
                        <td>3</td>
                    </tr>
                    <tr>
                        <td>Άλγεβρα</td>
                        <td>4</td>
                        <td>-</td>
                        <td>4</td>
                    </tr>
                    <tr>
                        <td>Φυσική</td>
                        <td>4</td>
                        <td>4</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>Χημεία</td>
                        <td>3</td>
                        <td>3</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>Βιολογία</td>
                        <td>-</td>
                        <td>3</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>Πληροφορική</td>
                        <td>-</td>
                        <td>-</td>
                        <td>3</td>
                    </tr>
                    <tr>
                        <td>Οικονομία</td>
                        <td>-</td>
                        <td>-</td>
                        <td>2</td>
                    </tr>
                    <tr>
                        <th>Σύνολο</th>
                        <td>14</td>
                        <td>13</td>
                        <td>12</td>
                    </tr>
                </table>
            </ScheduleButton>
        </div>
    </div>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import ScheduleButton from '@/components/ScheduleButton.vue'

export default {
    name: 'Schedule',
    components: {
        TitleBar,
        ScheduleButton
    }
}
</script>

<style scoped>
.content > * {
    margin-bottom: 3.7rem;
}

table {
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    line-height: 1.3em;
}

@media only screen and (max-width: 3840px) {
    table {
        font-size: 1.1em;
        width: 35em;
    }
}

@media only screen and (max-width: 1920px) {
    table {
        font-size: 1em;
        width: 32em;
    }
}

@media only screen and (max-width: 834px) {
    table {
        font-size: 0.9em;
        width: 20em;
    }
}

@media only screen and (max-width: 414px) {
    table {
        font-size: 0.85em;
        width: 17.5em;
    }
}

@media only screen and (max-width: 375px) {
    table {
        font-size: 0.8em;
        width: 17.5em;
    }
}

@media only screen and (max-width: 360px) {
    table {
        font-size: 0.79em;
        width: 16em;
    }
}
</style>