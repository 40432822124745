<template>
    <div class="age-container">
        <a class="schedule-button no-select" :style="{ backgroundColor: color }" v-on:click="toggleText()" v-html="name + ' ' + arrow"></a>

        <transition name="enlarge">
            <div class="text-area readable-text" v-if="showText">
                <slot></slot>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'ScheduleButton',
    props: ['name', 'color'],
    data() {
        return {
            arrow: '&#9662;',
            showText: false
        }
    },
    methods: {
        toggleText() {
            this.showText = !this.showText;
            this.arrow = this.showText == true ? '&#9652;' : '&#9662;';
        }
    }
}
</script>

<style scoped>
@keyframes enlarge {
    from { transform: scale(0); }
    to { transform: scale(1); }
}

.enlarge-enter-active {
    animation: enlarge 0.5s;
}

.enlarge-leave-active {
    animation: enlarge 0.5s reverse;
}

.age-container {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
}

.schedule-button {
    padding: 0.7em;
    margin-bottom: 0.7rem;
    font-size: 1.7em;
    text-decoration: underline;
    color: black;
}
</style>