<template>
    <div>
        <TitleBar />

        <p class="page-title big-text">Σχετικά με το E-Learning</p>

        <div class="text-area readable-text">
            Λόγω της πανδημίας και για την προστασία της δημόσιας υγείας, εφ' όσον χρειαστεί (ή επιβληθεί
            από το κράτος) η εκπαιδευτική διαδικασία θα συνεχίζεται υποχρεωτικά και <u>χωρίς διακοπή</u>
            με τη χρήση της σύγχρονης εξ' αποστάσεως διδασκαλίας.
            <br /><br />
            Με αυτό τον τρόπο, οι μαθητές θα συνεχίζουν χωρίς νά έχουν κενά στην ύλη της σχολικής χρονιάς.
        </div>
    </div>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'

export default {
    name: 'E-Learning',
    components: {
        TitleBar
    }
}
</script>

<style scoped>
</style>