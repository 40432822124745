<template>
    <div>
        <TitleBar />

        <p class="page-title big-text">Λύκειο 2020 - 2021</p>

        <div class="text-area readable-text">
            Για την εισαγωγή στα Πανεπιστήμια θα υπολογίζονται οι βαθμοί των <u>τεσσάρων</u> πανελλαδικώς
            εξεταζομένων μαθημάτων <u>σε ποσοστό 90%</u>.
            <br /><br />
            Επίσης:
            <ul>
                <li>ο βαθμός της Α' Λυκείου σε ποσοστό 2%</li>
                <li>ο βαθμός της Β' Λυκείου σε ποσοστό 3%</li>
                <li>ο βαθμός της Γ' Λυκείου σε ποσοστό 5%</li>
            </ul>
            <br />
            Στο τέλος της κάθε τάξης του Λυκείου, τα θέματα των εξετάσεων σε ποσοστό 50% θα προέρχονται
            με κλήρωση από την τράπεζα θεμάτων.
        </div>
    </div>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'

export default {
    name: 'HighSchool',
    components: {
        TitleBar
    }
}
</script>

<style scoped>
li {
    margin-left: 1.3rem;
}
</style>