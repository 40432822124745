<template>
    <a class="title-bar" href="https://www.hiqstudies.gr">
        <h1 class="no-select">HiQ Studies</h1>
        <img class="no-drag no-select" src="@/assets/logo.webp" alt="hiq logo" />
    </a>
</template>

<script>
export default {
    name: 'TitleBar'
}
</script>

<style scoped>
.title-bar {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 3em;
    background-color: rgb(30, 30, 30);
}

.title-bar > * {
    margin-left: 1em;
    margin-right: 1em;
}

a {
    text-decoration: none;
}

h1 {
    margin-top: 0;
    margin-bottom: 0;
    font-family: serif;
    color: rgb(240, 240, 240);
}

img {
    width: 2.5em;
    height: 2.5em;
}
</style>