<template>
    <transition name="fade">
        <img class="no-drag no-select" v-if="show" :src="selected" alt="φωτογραφία από φροντιστήριο" />
    </transition>
</template>

<script>
export default {
    name: 'BgImage',
    data() {
        return {
            wideImages: [
                require('@/assets/backgrounds/16:9/1.webp'),
                require('@/assets/backgrounds/16:9/2.webp'),
                require('@/assets/backgrounds/16:9/3.webp'),
                require('@/assets/backgrounds/16:9/4.webp'),
                require('@/assets/backgrounds/16:9/5.webp'),
                require('@/assets/backgrounds/16:9/6.webp'),
                require('@/assets/backgrounds/16:9/7.webp')
            ],
            tallImages: [
                require('@/assets/backgrounds/9:16/1.webp'),
                require('@/assets/backgrounds/9:16/2.webp'),
                require('@/assets/backgrounds/9:16/3.webp'),
                require('@/assets/backgrounds/9:16/4.webp'),
                require('@/assets/backgrounds/9:16/5.webp'),
                require('@/assets/backgrounds/9:16/6.webp'),
                require('@/assets/backgrounds/9:16/7.webp')
            ],
            show: true,
            imageSet: null,
            imageIndex: -1,
            imageEvent: null,
            selected: null
        }
    },
    methods: {
        onResize() {
            clearInterval(this.imageEvent);
            this.imageIndex--;
            this.startChoosing();
        },
        getImageSet() {
            var aspectRatio = window.innerWidth / window.innerHeight;
            return aspectRatio >= 1 ? this.wideImages : this.tallImages;
        },
        getImage() {
            return this.imageSet[++this.imageIndex % this.imageSet.length];
        },
        startChoosing() {
            this.imageSet = this.getImageSet();
            this.selected = this.getImage();

            this.imageEvent = setInterval(() => {
                this.show = false;
                this.selected = this.getImage();

                setTimeout(() => {
                    this.show = true;
                }, 1000);
            }, 8000);
        }
    },
    created() {
        window.addEventListener('resize', this.onResize);
        this.startChoosing();
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
    transition: opacity 1s;
}
.fade-enter, .fade-leave-to {
    opacity: 0;
}
</style>